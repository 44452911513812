
.email {
  padding: 64px;
  @media all and (max-width: 767px) {
    padding: 20px;
  }
  .card {
    max-width: 577px;
    background: #ffffff;
    box-shadow: 1px 4px 34px rgb(46 48 92 / 20%);
    margin: 0 auto;
    padding: 30px;
    img {
      display: block;
      margin: 0 auto;
      margin-bottom: 16px;
    }
    h1 {
      text-align: center;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 25px;
      text-align: center;

      color: #2e305c;
      margin-bottom: 20px;
    }
    p {
      text-align: center;
      font-style: normal;
      font-weight: 400;
      font-size: 18px !important;
      line-height: 17px;
      /* or 142% */
      text-align: center;
      color: #000;
      margin-bottom: 30px;
    }
    button.submit-btn {
      background: #fa8c28;
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;

      color: #ffffff;
      width: 100%;
      padding: 22.5px 0;
      margin-top: 10px;
      &:hover {
        opacity: 0.9;
      }
      &.disabled {
        background-color: #eeeeee;
        cursor: not-allowed;
        color: rgba(0, 0, 0, 0.3);
        pointer-events: none;
      }
    }
  }
  .footer {
      padding-top: 32px;
      padding-bottom: 22px;
      a {
        display: block;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */

        text-decoration-line: underline;

        color: #2e305c;
      }
    }
}
